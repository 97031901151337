import { Application } from "@hotwired/stimulus"
import Sortable from '@stimulus-components/sortable'
import { Autocomplete } from 'stimulus-autocomplete'


const application = Application.start()
application.register('sortable', Sortable)
application.register('autocomplete', Autocomplete)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
