import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(event) {
    const auctionId = this.element.dataset.auctionId;
    const checkboxType = event.target.dataset.checkboxType;
    const isChecked = event.target.checked;

    fetch(`/admin/auctions/${auctionId}/toggle_${checkboxType}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
      body: JSON.stringify({ [checkboxType]: isChecked })
    })
    .then(response => {
      if (!response.ok) throw new Error("Network response was not ok");
      return response.json();
    })
    .then(data => {
      console.log(`${checkboxType} updated successfully:`, data);
    })
    .catch(error => {
      console.error(`Error updating ${checkboxType}:`, error);
    });
  }
}
